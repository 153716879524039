.Nav{
    background-color:black;
    width:100vw;
    height:3em;
    display:flex;
    justify-content:center;
    align-content:center;
}
.ProductBar{
    width:100vw;
    height:3em;
    display:flex;
    justify-content:center;
    align-content:center;
    border-bottom: .25px solid #CECECF;
    position:sticky;
    top:0px;
    background-color:white;
    z-index:1;
}
.right-holder{
    display:flex;
    align-items:center;   
}
.price{
    margin-right:10px;
    margin-left:2px;
    font-weight:500;
}
.discounted{
    color:#0071e3;
    font-weight:500;
}

.header-containers{
width:80vw;
display:flex;
justify-content:space-between;
align-content:center;
}

.Title{
    font-size:1.25rem;
    align-self:center;
    font-weight:500;
}
.NavElement{
    color:white;
    align-self:center;
    list-style:none;
    display:inline;
    padding:10px;
    cursor:pointer;
}
.PurchaseButton{
    style:none;
    border: none;
    border-radius:30px;
    padding:0px 0px;
    height:25px;
    width:50px;
    align-self:center;
    background-color:#0071e3;
    color:white;
    cursor:pointer;
}
.PurchaseButton:hover{
    background-color:#147CE5;
}
.logo{
width:40px;
}
.NavElement .NavLink{
    text-decoration:none;
    color:white;
}